export default function() {
	const minItems = 7

	const list = document.getElementById('js-home-jobs')
	if (!list) return

	const items = [].filter.call(list.childNodes, child => child.nodeName === 'LI')
	if (items.length < minItems) return

	const container = list.parentNode
	const dupList = list.cloneNode()
	dupList.removeAttribute('id')
	for (let i = Math.ceil(items.length / 2); i < items.length; i++) {
		dupList.appendChild(items[i])
	}
	container.insertBefore(dupList, list.nextSibling)
}
