export default function() {
	const videoWidth = 1280
	const videoHeight = 720
	const container = document.getElementById('js-home-video-container')
	if (!container) return

	const video = document.createElement('video')
	video.className = 'js-home-video-video'
	video.src = env.assets + '/videos/interview-thumb.mp4'
	video.autoplay = 'true'
	video.loop = 'true'
	video.volume = 0
	container.appendChild(video)

	const adjust = () => {
		const cWidth = container.clientWidth
		const cHeight = container.clientHeight
		let width = videoWidth / 10
		let height = videoHeight / 10
		if (width < cWidth) {
			height *= cWidth / width
			width = cWidth
		}
		if (height < cHeight) {
			width *= cHeight / height
			height = cHeight
		}
		width = Math.round(width)
		height = Math.round(height)
		const offsetX = Math.round((cWidth - width) / 2)
		const offsetY = Math.round((cHeight - height) / 2)

		video.width = width
		video.height = height
		video.style.left = offsetX + 'px'
		video.style.top = offsetY + 'px'
	}

	adjust()
	window.addEventListener('resize', adjust)
}
