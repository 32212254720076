import jobs from './jobs'
import video from './video'
import ResponsiveSlideshow from './ResponsiveSlideshow'

jobs()
video()

;[].forEach.call(document.querySelectorAll('.slide-list'), (wrapE) => {
	new ResponsiveSlideshow(wrapE, { breakpoint: 750 })
})
