const breakpoint = 641
const instances = []

class ResponsiveSlideshow {
	constructor (wrapE, options = {}) {
		registerInstance(this)

		this.mobile = false
		this.wrapE = wrapE
		this.options = Object.assign({}, {
			breakpoint
		}, options)

		this.initSlideshow()
	}

	initSlideshow () {
		var me = this;

		me.mobile = me.isMobile()
		me.wrapE.setAttribute("data-images", me.wrapE.getAttribute(me.mobile ? "data-mobile-images" : "data-desktop-images"))
		new BS.Slideshow(me.wrapE, {
			interval: 5000,
			hoverToStop: true
		});
	}

	isMobile() {
		return (window.innerWidth < this.options.breakpoint)
	}

	resizeHandler() {
		var me = this

		if (me.isMobile() !== me.mobile) {
			me.initSlideshow()
		}
	}
}

function registerInstance (instance) {
	if (instances.length === 0) {
		window.addEventListener('resize', resizeHandler);
		window.addEventListener('orientationchange', resizeHandler);
	}
	instances.push(instance);
}

function resizeHandler () {
	instances.forEach(item => item.resizeHandler())
}

export default ResponsiveSlideshow
